import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import "./menu.scss";
import { Cross, Logo } from "../../assets/images/index";
function Menu({ menuOpen, handleClick }) {
  return (
    <div className={menuOpen ? "d-none" : "menu"}>
      <div className="main">
        <div className="banner-container pt-md-5 pt-3">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <div className="logo">
                <Link to="/">
                  {" "}
                  <img src={Logo} alt="Logo.png" />
                </Link>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-8">
              <div className="nav-btn-main">
                <div className="nav-btn" onClick={handleClick}>
                  <img src={Cross} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="sec-padding">
            <div className="row">
              <div className="col-md-12">
                <div className="main-navigation">
                  <ul>
                    <li>
                      <Link to={"/"} onClick={handleClick}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to={"/about"} onClick={handleClick}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to={"/services"} onClick={handleClick}>
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link to={"/portfolio"} onClick={handleClick}>
                        Portfolio
                      </Link>
                    </li>
                    <li>
                      <Link to={"/career"} onClick={handleClick}>
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"} onClick={handleClick}>
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to={"/team"} onClick={handleClick}>
                        Team
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
